@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
@layer utilities {
  .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}